<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">User</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Manage User</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Users</h4>
          </div>
        </div>
      </div>
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_user')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$store.dispatch('modalOpen','createUser')"
        >New User</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select v-model="params.pageIndex " @change="searchFilter()" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input type="text" v-model="params.searched" class="float-right" placeholder="Search..." @keyup="searchKeyword"/>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th scope="wd-5p table-start-item">S.N.</th>
              <th scope="wd-15">Name</th>
              <th scope="wd-10">Email</th>
              <th scope="wd-10">Phone</th>
              <th scope="wd-10">Address</th>
              <th scope="wd-15">Role</th>
              <th scope="wd-30">Status</th>
              <th scope="wd-5" class="text-center" v-if="checkIfAnyPermission(['view_user','edit_user','change_password'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && userLists.length > 0">
            <tr v-for="(user,index) in userLists" :key="index">
              <th scope="row">{{pageSerialNo+index}}.</th>
              <td>{{user.name}}</td>
              <td>{{user.email}}</td>
              <td>{{user.phone}}</td>
              <td>{{user.address}}</td>
              <td>
                <span v-for="(role,index) in user.roles" :key="index">{{role.name}}</span>
              </td>
              <td>
                
                <div class="custom-control custom-switch" v-if="checkSinglePermission('change_status') && user_priority < user.role_priority  || user_priority == 0">
                
                    <input
                    type="checkbox"
                    v-if="checkSinglePermission('change_status') && user_priority < user.role_priority  || user_priority == 0"
                    :checked="(user.status==1) ? true : false"
                    @change="userStatus(user.id)"
                    class="custom-control-input"
                    :id="'customSwitch'+user.id"
                  />
                    
                   <label
                    class="custom-control-label"
                    :for="'customSwitch'+user.id"
                  >{{(user.status==1) ? 'Active' : 'In-Active'}}</label> 
                </div>
                <div class="custom-control" v-else>
                  <label for="">{{(user.status==1) ? 'Active' : 'In-Active'}}</label>
                </div>
                
              </td>
              <td class="text-center" v-if="checkIfAnyPermission(['view_user','edit_user','change_password'])">
                <a
                  href="javascript:;"
                  @click="edit('editUser',user.id)"
                  v-if="checkSinglePermission('edit_user')  && user_priority < user.role_priority  || user_priority == 0"
                  title="Edit"
                  class="mr-3"
                >
                  <i class="fas fa-edit"></i>
                </a>
                 <a
                  href="javascript:;"
                  @click="changePassword('changePassword',user.id)"
                  v-if="checkSinglePermission('change_password') && user_priority < user.role_priority || user_priority == 0"
                  title="Change Password"
                  class="mr-3"
                >
                  <i class="fas fa-key"></i>
                </a>
                <!-- <a href="javascript:;" @click="drop(user.id)" title="Drop user">
                  <i class="fa fa-trash"></i>
                </a> -->
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && userLists.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td colspan="11" style="text-align:center;">{{error_message}}</td>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <Create @parent-event="getData" />
    <Edit @parent-event="getData" />
    <ChangePassword @parent-event="getData" />
  </div>
</template>

<script>
import Services from "./Services/Services";
import ChangePassword from "./changePassword";
import Paginate from "vuejs-paginate";
import { mapGetters } from "vuex";
import Edit from "./new-edit";
import Create from "./create";
import _ from "lodash";
export default {
  components: { Create, Edit, Paginate,ChangePassword },
  data() {
    return {
      loading: true,
      page: 1,
      error: false,
      params: {
        pageIndex: "10",
        searched: "",
        offset: 0,
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      disableCheckBox:false,
      error_message:'',
      user_priority:'',
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("users", ["userLists", "userElements"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalOpen", modal);
    },
    changePassword(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalOpen", modal);
    },
    // drop(id) {
    //   this.$store.commit("setDataId", id);
    //   this.$store.commit("dropRecord", "api/users/" + id);
    // },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    getData() {
      this.loading = true;
      Services.getUserList(this.params)
        .then((res) => {
          this.$store.commit("users/setUserLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.user_priority = res.data.data.user_priority;
          this.getElements();
        })
        .catch((err) => {
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
        }).finally(()=>{
          this.loading=false;
        });
    },
    getElements() {
      Services.getElements()
        .then((res) => {
          this.$store.commit("users/setUserElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchFilter(){
      this.params.offset = 0;
      this.pageSerialNo=1;
      this.page = 1;
      this.getData();
    },
    searchKeyword:_.debounce(function(){
      this.params.offset = 0;
      this.pageSerialNo=1;
      this.page = 1;
      this.getData();
    },2000),
    userStatus(id){
      this.disableCheckBox=true;
      Services.changeStatus(id).then(res=>{
        if(res.status == 200){
          this.userLists.forEach((item,index) => {
              if(item.id == id){
                   if(item.status == 0){
                      this.userLists[index]['status'] = 1;
                   }else{
                      this.userLists[index]['status'] = 0;
                   }
                }              
              });
        this.setNotification(res.data.success_message);
        }
      }).catch(err=>{
         if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
          }else{
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
      }).finally(()=>{
          this.disableCheckBox = false;
      });
    }
  },
};
</script>